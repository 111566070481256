import { GoDotFill } from "react-icons/go";
import { IoMdInformationCircle } from "react-icons/io";
import AudienceAgeGraph from "./AudienceAgeGraph";
export default function AudienceAge({ data }) {
  return (
    <div className="p-4 border-[1px] border-[#dee2e6] bg-white h-full audience-gender rounded-[8px]">
      <div className="flex justify-between">
        <h5 className="font-normal text-[16px] text-[#8d8d8d] flex items-center uppercase">
          Audience ages{" "}
          <IoMdInformationCircle size={20} className="ml-2 text-[#c4c4c4]" />
        </h5>
        <div className="font-normal text-[16px] text-[#8d8d8d] flex gap-x-5 pr-10">
          <div className="flex items-center">
            <GoDotFill className="pink shrink-0" size={22} />
            <p className="text-[16px] text-[#8d8d8d] text-normal">Women</p>
          </div>
          <div className="flex items-center">
            <GoDotFill className="purple shrink-0" size={22} />
            <p className="text-[16px] text-[#8d8d8d] text-normal">Men</p>
          </div>
        </div>
      </div>
      <AudienceAgeGraph data={data} />
    </div>
  );
}
