import React from "react";
import { connect } from "react-redux";
import Influencify from "../../../constants/Influencify";

class AnalyticsVerifyToken extends React.Component {
  async componentDidMount() {
    const code = this.props.params;
    if (code) {
      const data = { code: code };
      await Influencify.connectGoogleAnalytics(data);
      this.props.navigate("/integration/analytics");
    }
  }

  render() {
    return (
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div>
          <h3 className="text-gray-700 text-3xl font-semibold">
            Connecting...
          </h3>
        </div>
      </div>
    );
  }
}

export default connect(null)(AnalyticsVerifyToken);
