import ProxyImage from "@components/ProxyImage";
import React from "react";
import { ImPlay2 } from "react-icons/im";
import { Link } from "react-router-dom";

const ContentDisplay = ({ content }) => {
  return (
    <div className="relative w-full h-[300px]">
      {content.is_s3 ? (
        <img
          className="rounded-[8px] w-full h-full"
          src={`${process.env.REACT_APP_INFLUENCIFY_WEB_CONTENT}/${
            content.s3_path || content.s3_image_path
          }`}
          alt=""
        />
      ) : (
        <ProxyImage
          className="rounded-[8px] w-full h-full"
          alt=""
          url={content.media_pic}
          addCancelToken={() => {}}
        />
      )}
      {(content.s3_video_path || content.media_url) && (
        <Link
          to={
            content.s3_video_path
              ? `${process.env.REACT_APP_INFLUENCIFY_WEB_CONTENT}/${content.s3_video_path}`
              : content.media_url
          }
          target="_blank"
          className="absolute inset-0 flex items-center justify-center"
        >
          <ImPlay2 size={60} color="white" />
        </Link>
      )}
    </div>
  );
};

export default ContentDisplay;
