import {
  AJAX_CALL_INIT,
  AJAX_CALL_FINSH,
  HANDLE_BRAND_SHOPIFY_VERIFY_TOKEN_SUCCESS,
} from "../constants/action-types";
import { toast } from "react-toastify";
import Api from "@services/axios";

export const brandVerifyShopifyToken = async (ownProps, dispatch) => {
  dispatch({
    type: AJAX_CALL_INIT,
  });
  const json = await Api.BrandVerifyShopifyToken(ownProps);
  dispatch({
    type: HANDLE_BRAND_SHOPIFY_VERIFY_TOKEN_SUCCESS,
  });
  dispatch({
    type: AJAX_CALL_FINSH,
  });

  return json;
};

export const disconnectShopifyStore = () => (dispatch) => {
  dispatch({
    type: AJAX_CALL_INIT,
  });

  Api.DisconnectShopifyStore()
    .then((res) => {
      dispatch({
        type: "HANDLE_BRAND_SHOPIFY_DISCONNECT",
      });
      dispatch({
        type: AJAX_CALL_FINSH,
      });
    })
    .catch((error) => {});
};

export const startImportProduct = () => (dispatch) => {
  dispatch({
    type: AJAX_CALL_INIT,
  });

  Api.StartImportProduct()
    .then((res) => {
      toast.success(res.data);
      dispatch({
        type: AJAX_CALL_FINSH,
      });
    })
    .catch((error) => {});
};

export const startImportCustomer = (data) => (dispatch) => {
  dispatch({
    type: AJAX_CALL_INIT,
  });

  Api.StartImportCustomer(data)
    .then((res) => {
      toast.success(res.data);
      dispatch({
        type: AJAX_CALL_FINSH,
      });
    })
    .catch((error) => {});
};
