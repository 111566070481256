import Api from "@services/axios";
import { FETCH_PRODUCTS_SUCCESS } from "../constants/action-types";
import { FETCH_PRODUCTS_FAILURE } from "../constants/action-types";
import { HANDLE_FETCH_ALL_PRODUCTSS_SUCCESS } from "../constants/action-types";
import { HANDLE_FETCH_ALL_PRODUCTSS_FAILURE } from "../constants/action-types";

export const fetchProducts = (query) => (dispatch) => {
  return new Promise((resolve) => {
    Api.FetchProducts(query)
      .then((res) => {
        dispatch({
          type: FETCH_PRODUCTS_SUCCESS,
          payload: res.data,
        });
        resolve(true);
      })
      .catch((res) => {
        dispatch({
          type: FETCH_PRODUCTS_FAILURE,
          payload: res.error,
        });
        resolve(true);
      });
  });
};

export const fetchAllProducts = (query) => (dispatch) => {
  Api.FetchAllProducts(query)
    .then((res) => {
      dispatch({
        type: HANDLE_FETCH_ALL_PRODUCTSS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((res) => {
      dispatch({
        type: HANDLE_FETCH_ALL_PRODUCTSS_FAILURE,
        payload: res.error,
      });
    });
};
