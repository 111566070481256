import { Dialog, Transition } from "@headlessui/react";
import { Fragment, forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiX } from "react-icons/fi";
import { actions } from "@store/redux/SettingRedux";
import { FaSpinner } from "react-icons/fa";

const DetailPopup = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.settings.isFetchingLog);
  const activityLog = useSelector((state) => state.settings.activityLog);

  const fetchActivityLog = (id) => {
    actions.fetchActivityLog(dispatch, id);
  };

  useImperativeHandle(ref, () => ({
    open(id) {
      setIsOpen(true);
      fetchActivityLog(id);
    },
    close() {
      setIsOpen(false);
    },
  }));

  const log = { ...activityLog };
  delete log.properties?.requestData?.user;

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog onClose={() => setIsOpen(false)} className="relative z-[100]">
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[70%] mx-auto rounded-[8px] bg-white">
                <Dialog.Title className="text-white text-center grow flex justify-between border-b border-[#dee2e6] p-3 sticky top-0 bg-white z-10">
                  <h2 className="text-[24px] font-medium text-black">
                    Credit History Insights
                  </h2>
                  <div
                    className="px-[12px] rounded-full mt-[2px] mr-[13px] h-[40px] w-[40px] shadow-[0px_10px_30px_#96969640] flex items-center cursor-pointer"
                    onClick={() => setIsOpen(false)}
                  >
                    <FiX size={24} className="text-black stroke-black" />
                  </div>
                </Dialog.Title>
                {loader ? (
                  <div className="flex items-center h-[80vh] overflow-hidden">
                    <FaSpinner
                      size={66}
                      className="animate-[spin_2s_linear_infinite] pink mx-auto"
                    />
                  </div>
                ) : (
                  <div className="p-3 overflow-auto max-h-[90vh] text-lg space-y-5">
                    {Object.entries(activityLog).length > 1 ? (
                      <>
                        <div className="grid grid-cols-5">
                          <h1 className="col-span-1 font-medium">
                            Description:
                          </h1>{" "}
                          <p className="col-span-4">
                            {activityLog.description}
                          </p>
                        </div>
                        <div className="grid grid-cols-5 items-center">
                          <h1 className="col-span-1 font-medium">API path:</h1>{" "}
                          <pre className="col-span-4 mt-2 p-4 bg-gray-100 rounded-md">
                            {activityLog.properties?.requestPath}
                          </pre>
                        </div>
                        <div className="grid grid-cols-5">
                          <h1 className="col-span-1 font-medium">
                            Request Data:
                          </h1>{" "}
                          <pre className="col-span-4 mt-2 p-4 bg-gray-100 rounded-md">
                            {" "}
                            {JSON.stringify(
                              activityLog.properties?.requestData,
                              null,
                              2
                            )}
                          </pre>
                        </div>
                        <div className="grid grid-cols-5">
                          <h1 className="col-span-1 font-medium">Response:</h1>{" "}
                          <pre className="col-span-4 mt-2 p-4 bg-gray-100 rounded-md max-h-[470px] overflow-auto">
                            {" "}
                            {JSON.stringify(
                              activityLog.properties?.response,
                              null,
                              2
                            )}
                          </pre>{" "}
                        </div>
                      </>
                    ) : (
                      <div className="text-center w-full py-[5rem] px-[1rem] justify-center text-[#bbb] text-[28px] font-medium leading-[40px]">
                        We have nothing to show you here.
                      </div>
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
});

export default DetailPopup;
