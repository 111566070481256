import { IoMdInformationCircle } from "react-icons/io";
import Followers from "@assets/svgs/followers_alt.webp";
import AudienceGraph from "./AudienceGraph";

const formatedNumber = (num, decimal = 2) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(decimal).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(decimal).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(decimal).replace(/\.0$/, "") + "K";
  }
  return num;
};

const AudienceReachability = ({ profile, data }) => {
  return (
    <div className="bg-white rounded-[8px] p-6 h-full">
      <div className="flex items-end">
        <img src={Followers} alt="followers" className="w-[40px]" />
        <h4 className="ml-6 text-[30px] leading-[30px] text-[#8d8d8d] font-semibold">
          {formatedNumber(profile?.followers, 1)}
        </h4>
        <p className="text-[14px] text-normal text-[#8d8d8d] ml-3">Followers</p>
        <IoMdInformationCircle
          size={20}
          className="text-[#8d8d8d] ml-2 shrink-0"
        />
      </div>
      <p className="text-[14px] font-normal text-[#8d8d8d] mt-5 mb-16">
        Audience Reachability
      </p>
      {data?.length > 0 && <AudienceGraph data={data} />}
    </div>
  );
};

export default AudienceReachability;
