import React from "react";
import Slider from "react-slick";
import ContentDisplay from "./ContentDisplay";

export default function SimpleSlider({ batch, setCurrentSlide }) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    afterChange: (index) => {
      const nextIndex = index % batch.length;
      setCurrentSlide(batch[nextIndex]);
    },
  };
  return (
    <Slider {...settings} className="reports_slider">
      {batch.map((content) => (
        <ContentDisplay content={content} />
      ))}
    </Slider>
  );
}
