import helper from "../../constants/helper";
import { HANDEL_BRAND_BASIC_INFO_SETTIGNS_SUCCESS } from "../constants/action-types";
import { HANDEL_BRAND_BASIC_INFO_SETTIGNS_FAILURE } from "../constants/action-types";
import { HANDLE_VALIDATION_ERRORS } from "../constants/action-types";
import { HANDLE_UPDATE_AVATAR_SUCCESS } from "../constants/action-types";
import { HANDLE_UPDATE_AVATAR_FAILURE } from "../constants/action-types";
import { HANDLE_UPDATE_PROFILE_SUCCESS } from "../constants/action-types";
import { HANDLE_UPDATE_PROFILE_FAILURE } from "../constants/action-types";
import { currentLoggedInUser } from "./HeaderActions";
import { AJAX_CALL_INIT, AJAX_CALL_FINSH } from "../constants/action-types";
import { toast } from "react-toastify";
import Api from "@services/axios";

export const brandBasicInfoSettigs = () => (dispatch) => {
  dispatch({
    type: AJAX_CALL_INIT,
  });
  Api.BrandBasicInfoSettigs()
    .then((res) => {
      dispatch({
        type: HANDEL_BRAND_BASIC_INFO_SETTIGNS_SUCCESS,
        payload: res.data.data,
      });
      dispatch({
        type: AJAX_CALL_FINSH,
      });
    })
    .catch((error) => {
      dispatch({
        type: HANDEL_BRAND_BASIC_INFO_SETTIGNS_FAILURE,
        payload: error,
      });
    });
};

export const handleChangeAvatar = (query) => (dispatch) => {
  Api.HandleChangeAvatar(query)
    .then((res) => {
      if (res.data.errors) {
        dispatch({
          type: HANDLE_VALIDATION_ERRORS,
          payload: res.data.errors,
        });
      } else {
        dispatch({
          type: HANDLE_UPDATE_AVATAR_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: "HANDLE_ON_DROP_FILE",
          payload: query.brand_avatar?.[0],
        });

        dispatch(currentLoggedInUser());
        toast.success(helper.successMsg);
      }
    })
    .catch((error) => {
      dispatch({
        type: HANDLE_UPDATE_AVATAR_FAILURE,
        payload: error,
      });
    });
};

export const handleBrandBasicInfoUpdate = (query) => (dispatch) => {
  dispatch({
    type: AJAX_CALL_INIT,
  });

  Api.HandleBrandBasicInfoUpdate(query)
    .then((res) => {
      if (res.data.errors) {
        dispatch({
          type: HANDLE_VALIDATION_ERRORS,
          payload: res.data.errors,
        });
        dispatch({
          type: AJAX_CALL_FINSH,
        });
      } else {
        dispatch({
          type: HANDLE_UPDATE_PROFILE_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: AJAX_CALL_FINSH,
        });
        dispatch(currentLoggedInUser());
        toast.success(helper.successMsg);
      }
    })
    .catch((error) => {
      dispatch({
        type: HANDLE_UPDATE_PROFILE_FAILURE,
        payload: error,
      });
    });
};
