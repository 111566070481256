import { Component } from "react";
import { Link } from "react-router-dom";
import Button from "@components/global/Button";
import SettingHeader from "@components/BrandSettings/SettingHeader";
import SettingBrandTopTab from "@components/SettingBrandTopTab";
import SettingBrandSidebar from "@components/SettingBrandSidebar";
import BrandAvatarModal from "@components/BrandAvatarModal";
import avatar from "@assets/avatar.webp";
import { connect } from "react-redux";
import * as basicInfoActionsCreator from "@store/actions/settingBasicInfoActions";
import * as settingAccountActionCreator from "@store/actions/SettingAccountActions";
import Noty from "noty";
import "./styles.css";
import { FaSpinner } from "react-icons/fa";
import zxcvbn from "zxcvbn";

import {
  HANDLE_CHANGE_SUCCESS,
  HANDEL_ACTIVE_MODAL_SHOW,
  HANDLE_ON_DROP_FILE,
  HANDLE_SELECT_COUNTRY_SUCCESS,
  HANDLE_SELECT_STATE_SUCCESS,
  HANDLE_SELECT_CITY_SUCCESS,
  HANDLE_SELECT_TIMEZONE_SUCCESS,
  HANDLE_SELECT_CURRENCY_SUCCESS,
} from "@store/constants/action-types";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

class BrandSettingProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: this.props.avatar ? this.props.avatar : avatar,
      newUrl: "",
      flag: true,
      currency: "",
      currencies: this.props.currencies,
      type: "password",
    };
    this.timeout = 0;
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount() {
    this.props.brandBasicInfoSettigs();
  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({ avatar: pictureDataURLs, newUrl: pictureDataURLs });
  }

  handleAvatarChange = () => {
    const query = { brand_avatar: this.state.newUrl };
    this.props.handleChangeAvatar(query);
    this.props.setActivateModalShow(false);
    this.setState({ flag: false });
  };

  handleUpdateBasicInfo = (event) => {
    var reset = {
      target: {
        value: "",
        name: "password",
      },
    };
    const query = {
      name: this.props.name,
      first_name: this.props.first_name,
      last_name: this.props.last_name,
      email: this.props.email,
      password: this.props.password,
      password_strength: zxcvbn(this.props.password),
    };
    this.props.handleBrandBasicInfoUpdate(query);
    this.props.handleChange(reset);
  };

  handleCountrySelect = async (event) => {
    this.props.handleSelectCountry(event);
    this.props.fetchStates(event.value);
    this.props.fetchTimeZones(event.value);
    this.setState({ flag: false });
    const result = Object.assign(
      {},
      this.props.countries.find((o) => o.name === event.label)
    );
    let code = {
      code: result.country_code,
    };
    this.props.fetchCurrency(code);
  };

  handleStateSelect = (event) => {
    this.props.handleSelectState(event);
    this.setState({ flag: false });
    this.props.fetchCities(event.value);
  };

  showSuccessMessage = (msg) => {
    new Noty({
      type: "success",
      theme: "sunset",
      text: msg,
      layout: "topRight",
      timeout: 2000,
    }).show();
  };

  handleVisible = (shown, type) => {
    this.setState({
      shown: shown,
      type: type,
    });
  };

  createPasswordVariant = (result) => {
    switch (result.score) {
      case 0:
        return "#dc3545";
      case 1:
        return "#dc3545";
      case 2:
        return "#ffc107";
      case 3:
        return "#17a2b8";
      case 4:
        return "#28a745";
      default:
        return "#dc3545";
    }
  };

  createPasswordLength = (result) => {
    switch (result.score) {
      case 0:
        if (result.password.length > 0) {
          return 10;
        }
        return 0;
      case 1:
        return 25;
      case 2:
        return 50;
      case 3:
        return 75;
      case 4:
        return 100;
      default:
        return 0;
    }
  };

  createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return "Weak";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Weak";
    }
  };

  render() {
    const givenPassword = zxcvbn(this.props.password);

    const { refreshData } = this.props;

    return (
      <div>
        <SettingHeader />
        <SettingBrandTopTab />
        <div className="containers mb-12">
          <div className="grid grid-cols-12 gap-5">
            <div className="md:col-span-3 sm:col-span-6 lg:col-start-1 sm:col-start-4 col-span-12">
              <SettingBrandSidebar />
            </div>
            <div className="md:col-span-9 col-span-12 mt-12 md:!mt-0">
              <h4 class="font-semibold mb-4 text-[20px]">Account</h4>
              <div className="shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] bg-white rounded-[8px] p-3 sm:!p-12 space-y-5">
                <div className="grid grid-cols-12 gap-5">
                  <div className="sm:col-span-7 col-span-12">
                    {refreshData.is_main && (
                      <>
                        <div className="mt-2">
                          <label className="text-[14px] font-medium">
                            First name
                          </label>
                          <input
                            type="text"
                            name="first_name"
                            disabled={!refreshData.is_main}
                            value={this.props.first_name || ""}
                            onChange={(e) => {
                              this.props.handleChange(e);
                              this.setState({ flag: false });
                            }}
                            placeholder="First name"
                            className="rounded-[8px] h-[40px] inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-[#7c3292]"
                          />
                          {this.props.errorsObj &&
                          this.props.errorsObj?.first_name ? (
                            <span className="red">
                              {this.props.errorsObj.first_name[0]}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="mt-5">
                          <label className="text-[14px] font-medium">
                            Last name
                          </label>
                          <input
                            type="text"
                            name="last_name"
                            disabled={!refreshData.is_main}
                            value={this.props.last_name || ""}
                            onChange={(e) => {
                              this.props.handleChange(e);
                              this.setState({ flag: false });
                            }}
                            placeholder="Last name"
                            className="rounded-[8px] h-[40px] inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-[#7c3292]"
                          />
                          {this.props.errorsObj &&
                          this.props.errorsObj?.last_name ? (
                            <span className="red">
                              {this.props.errorsObj.last_name[0]}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}

                    <form className="mt-5">
                      <label className="text-[14px] font-medium">
                        Brand name
                      </label>
                      <input
                        type="text"
                        name="name"
                        disabled={!refreshData.is_main}
                        value={this.props.name || ""}
                        onChange={(e) => {
                          this.props.handleChange(e);
                          this.setState({ flag: false });
                        }}
                        placeholder="Brand name"
                        className="rounded-[8px] h-[40px] inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-[#7c3292]"
                      />
                      {this.props.errorsObj && this.props.errorsObj?.name ? (
                        <span className="red">
                          {this.props.errorsObj.name[0]}
                        </span>
                      ) : (
                        ""
                      )}
                    </form>
                  </div>
                  <div className="sm:col-span-5 col-span-12 text-center">
                    <img
                      className="object-cover w-[110px] h-[110px] rounded-full mx-auto"
                      src={this.props.avatar ? this.props.avatar : avatar}
                      alt="avatar"
                    />
                    {refreshData.is_main && (
                      <div className="">
                        <Link
                          to="#"
                          className="inline-block success"
                          onClick={() => this.props.setActivateModalShow(true)}
                        >
                          Edit Brand Avatar
                        </Link>
                      </div>
                    )}
                    <BrandAvatarModal
                      show={this.props.ActivateModalShow}
                      onHide={() => this.props.setActivateModalShow(false)}
                      cancelhandleChange={this.cancelhandleChange}
                      onDrop={this.onDrop}
                      defaultImage={
                        this.props.avatar ? this.props.avatar : avatar
                      }
                      handleChangeAvatar={() => this.handleAvatarChange()}
                    />
                  </div>
                </div>

                {refreshData.is_main && (
                  <>
                    <div className="grid grid-cols-12 gap-5">
                      <div className="sm:col-span-7 col-span-12">
                        <form className="">
                          <label className="text-[14px] font-medium">
                            Email
                          </label>
                          <input
                            type="text"
                            name="email"
                            disabled={!refreshData.is_admin}
                            value={this.props.email || ""}
                            onChange={(e) => {
                              this.props.handleChange(e);
                              this.setState({ flag: false });
                            }}
                            placeholder="Email"
                            className="rounded-[8px] h-[40px] inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-[#7c3292]"
                          />
                          {this.props.errorsObj &&
                          this.props.errorsObj?.email ? (
                            <span className="red">
                              {this.props.errorsObj.email[0]}
                            </span>
                          ) : (
                            ""
                          )}
                        </form>
                      </div>
                      <div className="sm:col-span-5 col-span-12">
                        <form>
                          <label className="text-[14px] font-medium">
                            Password
                          </label>
                          <div className="relative">
                            <input
                              type={this.state.type}
                              value={this.props.password}
                              placeholder="Password"
                              name="password"
                              onChange={(e) => {
                                this.props.handleChange(e);
                                this.setState({ flag: false });
                              }}
                              className="pr-12 rounded-[8px] h-[40px] inline-flex w-full items-center pl-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-[#7c3292]"
                            />
                            {this.state.shown ? (
                              <AiOutlineEyeInvisible
                                size={22}
                                onClick={() =>
                                  this.handleVisible(false, "password")
                                }
                                className="absolute z-1 top-[10px] right-[10px] cursor-pointer darkGray"
                              />
                            ) : (
                              <AiOutlineEye
                                size={22}
                                onClick={() => this.handleVisible(true, "text")}
                                className="absolute z-1 top-[10px] right-[10px] cursor-pointer darkGray"
                              />
                            )}
                          </div>
                          {this.props.errorsObj?.password ? (
                            <span className="red">
                              {this.props.errorsObj.password[0]}
                            </span>
                          ) : (
                            ""
                          )}
                        </form>
                        <div className="sm:col-span-4 col-span-12 mt-2 pt-2"></div>

                        <div className="bg-[#e9ecef] h-[20px] rounded-[8px] overflow-hidden leading-[0px]">
                          <div
                            className={`flex flex-col justify-center overflow-hidden text-white text-center whitespace-nowrap transition-all duration-[600ms] h-full text-[0.75rem]`}
                            style={{
                              backgroundColor:
                                this.createPasswordVariant(givenPassword),
                              width: `${this.createPasswordLength(
                                givenPassword
                              )}%`,
                            }}
                          >
                            {this.createPasswordLabel(givenPassword)}
                          </div>
                        </div>
                      </div>
                    </div>

                    <Button
                      className="w-s mt-6 px-12 rounded-[8px] h-[40px] text-[14px] inline-flex justify-center items-center bg--purple text-white disabled:opacity-80 hover:opacity-80"
                      onClick={() => this.handleUpdateBasicInfo()}
                      disabled={this.state.flag}
                      text={
                        this.props.isLoading ? (
                          <FaSpinner className="animate-[spin_2s_linear_infinite]" />
                        ) : (
                          "Save Changes"
                        )
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isPlanSubscribed: state.HeaderReducer.isPlanSubscribed,
    isLoading: state.BasicInfoReducer.isLoading,
    errorsObj: state.BasicInfoReducer.errorsObj,
    ActivateModalShow: state.BasicInfoReducer.ActivateModalShow,
    name: state.BasicInfoReducer.name,
    first_name: state.BasicInfoReducer.first_name,
    email: state.BasicInfoReducer.email,
    last_name: state.BasicInfoReducer.last_name,
    profile_pic: state.BasicInfoReducer.profile_pic,
    avatar: state.BasicInfoReducer.avatar,
    country: state.BasicInfoReducer.country,
    state: state.BasicInfoReducer.state,
    password: state.SettingAccountReducer.password,
    city: state.BasicInfoReducer.city,
    timeZone: state.BasicInfoReducer.timeZone,
    currency: state.BasicInfoReducer.currency,
    is_currency_set: state.BasicInfoReducer.is_currency_set,
    website: state.BasicInfoReducer.website,
    user_description: state.BasicInfoReducer.user_description,
    review_visible: state.BasicInfoReducer.review_visible,
    refreshData: state.HeaderReducer.refreshData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (event) =>
      dispatch({ type: HANDLE_CHANGE_SUCCESS, payload: event }),
    setActivateModalShow: (event) =>
      dispatch({ type: HANDEL_ACTIVE_MODAL_SHOW, payload: event }),
    handleOnDrop: (event) =>
      dispatch({ type: HANDLE_ON_DROP_FILE, payload: event }),
    handleSelectCountry: (event) =>
      dispatch({ type: HANDLE_SELECT_COUNTRY_SUCCESS, payload: event }),
    handleSelectState: (event) =>
      dispatch({ type: HANDLE_SELECT_STATE_SUCCESS, payload: event }),
    handleSelectCity: (event) =>
      dispatch({ type: HANDLE_SELECT_CITY_SUCCESS, payload: event }),
    handleSelectCurrency: (event) =>
      dispatch({ type: HANDLE_SELECT_CURRENCY_SUCCESS, payload: event }),
    handleSelectTimeZone: (event) =>
      dispatch({ type: HANDLE_SELECT_TIMEZONE_SUCCESS, payload: event }),
    brandBasicInfoSettigs: () =>
      dispatch(basicInfoActionsCreator.brandBasicInfoSettigs()),
    handleChangeAvatar: (query) =>
      dispatch(basicInfoActionsCreator.handleChangeAvatar(query)),
    handleBrandBasicInfoUpdate: (query) =>
      dispatch(basicInfoActionsCreator.handleBrandBasicInfoUpdate(query)),
    brandAccountSettings: () =>
      dispatch(settingAccountActionCreator.brandAccountSettings()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandSettingProfile);
