import {
  AJAX_CALL_INIT,
  AJAX_CALL_FINSH,
  FETCH_BRAND_NOTABLE_USERS_SUCCESS,
  FETCH_BRAND_NOTABLE_USERS_FAILURE,
  BRAND_INFLUENCIAL_FOLLOWERS_LOADING,
} from "../constants/action-types";
import Api from "@services/axios";

export const fetchBrandNotableUsers = (query) => (dispatch) => {
  dispatch({
    type: BRAND_INFLUENCIAL_FOLLOWERS_LOADING,
  });
  dispatch({
    type: AJAX_CALL_INIT,
  });

  Api.BrandNotableUsers(query)
    .then((res) => {
      dispatch({
        type: AJAX_CALL_FINSH,
      });
      dispatch({
        type: FETCH_BRAND_NOTABLE_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: AJAX_CALL_FINSH,
      });
      dispatch({
        type: FETCH_BRAND_NOTABLE_USERS_FAILURE,
      });
    });
};
