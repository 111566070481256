import { toast } from "react-toastify";
import Influencify from "../../constants/Influencify";

export const types = {
  FETCH_USAGE_CREDIT_HISTORY_PENDING: "FETCH_USAGE_CREDIT_HISTORY_PENDING",
  FETCH_USAGE_CREDIT_HISTORY_SUCCESS: "FETCH_USAGE_CREDIT_HISTORY_SUCCESS",
  FETCH_USAGE_CREDIT_HISTORY_MORE: "FETCH_USAGE_CREDIT_HISTORY_MORE",
  FETCH_USAGE_CREDIT_HISTORY_FAILURE: "FETCH_USAGE_CREDIT_HISTORY_FAILURE",

  FETCH_ACCOUNT_HISTORY_PENDING: "FETCH_ACCOUNT_HISTORY_PENDING",
  GENERATE_API_CREDENTIALS_PENDING: "GENERATE_API_CREDENTIALS_PENDING",
  FETCH_API_CREDENTIALS_SUCCESS: "FETCH_API_CREDENTIALS_SUCCESS",
  FETCH_ACCOUNT_HISTORY_SUCCESS: "FETCH_ACCOUNT_HISTORY_SUCCESS",
  FETCH_ACCOUNT_HISTORY_MORE: "FETCH_ACCOUNT_HISTORY_MORE",
  FETCH_ACCOUNT_HISTORY_FAILURE: "FETCH_ACCOUNT_HISTORY_FAILURE",
  CHANGE_IP_ADDRESS_PENDING: "CHANGE_IP_ADDRESS_PENDING",
  FETCH_ACTIVITY_LOG_INIT: "FETCH_ACTIVITY_LOG_INIT",
  FETCH_ACTIVITY_LOG_SUCCESS: "FETCH_ACTIVITY_LOG_SUCCESS",
};

export const actions = {
  fetchUsageCreditHistory: async (dispatch, page, params) => {
    dispatch({ type: types.FETCH_USAGE_CREDIT_HISTORY_PENDING });
    const json = await Influencify.fetchUsageCreditHistory(page, params);
    if (json.status === 200) {
      if (page > 1) {
        dispatch({
          type: types.FETCH_USAGE_CREDIT_HISTORY_MORE,
          data: json.data,
        });
      } else {
        dispatch({
          type: types.FETCH_USAGE_CREDIT_HISTORY_SUCCESS,
          data: json.data,
        });
      }
    } else {
      dispatch({ type: types.FETCH_USAGE_CREDIT_HISTORY_FAILURE });
    }
  },

  fetchAccountHistory: async (dispatch, page, params) => {
    dispatch({ type: types.FETCH_ACCOUNT_HISTORY_PENDING });
    const json = await Influencify.fetchAccountHistory(page, params);
    if (json.status === 200) {
      if (page > 1) {
        dispatch({ type: types.FETCH_ACCOUNT_HISTORY_MORE, data: json.data });
      } else {
        dispatch({
          type: types.FETCH_ACCOUNT_HISTORY_SUCCESS,
          data: json.data,
        });
      }
    } else {
      dispatch({ type: types.FETCH_ACCOUNT_HISTORY_FAILURE });
    }
  },

  fetchApiCredentials: async (dispatch) => {
    const json = await Influencify.fetchApiCredentials();
    if (json.status === 200) {
      dispatch({
        type: types.FETCH_API_CREDENTIALS_SUCCESS,
        data: json.data,
      });
    } else {
      toast.error("Something went wrong!");
    }
    return json;
  },

  fetchActivityLog: async (dispatch, id) => {
    dispatch({ type: types.FETCH_ACTIVITY_LOG_INIT });
    const json = await Influencify.fetchActivityLog(id);
    if (json.status !== 200) {
      toast.error("Activity Log not found!");
    }
    dispatch({
      type: types.FETCH_ACTIVITY_LOG_SUCCESS,
      data: json?.data,
    });
  },

  generateApiCredentials: async (dispatch) => {
    dispatch({ type: types.GENERATE_API_CREDENTIALS_PENDING });
    const json = await Influencify.generateApiCredentials();
    if (json.status === 200) {
      dispatch({
        type: types.FETCH_API_CREDENTIALS_SUCCESS,
        data: json.data,
      });
      toast.success("API credentials generated!");
    } else {
      toast.error("Something went wrong!");
    }
  },

  regenerateSecretKey: async (dispatch) => {
    dispatch({ type: types.GENERATE_API_CREDENTIALS_PENDING });
    const json = await Influencify.regenerateSecretKey();
    if (json.status === 200) {
      dispatch({
        type: types.FETCH_API_CREDENTIALS_SUCCESS,
        data: json.data,
      });
      toast.success("Password regenerated!");
    } else {
      toast.error("Something went wrong!");
    }
  },

  changeIPAddress: async (dispatch, query) => {
    dispatch({ type: types.CHANGE_IP_ADDRESS_PENDING });
    const json = await Influencify.changeIPAddress(query);
    if (json.status === 200) {
      dispatch({
        type: types.FETCH_API_CREDENTIALS_SUCCESS,
        data: json.data,
      });
      toast.success("IP Address saved!");
    } else {
      toast.error("Something went wrong!");
    }
  },
};

const initialState = {
  isFetching: false,
  creditHistory: [],
  totalCreditHistory: 0,
  hasMoreCreditHistory: false,
  totalCreditDebited: 0,
  totalCreditCredited: 0,
  activityLog: {},
  isAccoutFetching: false,
  accountHistory: [],
  clientCredentials: {},
  totalAccountHistory: 0,
  hasMoreAccountHistory: false,
  isGeneratingApiCredentials: false,
  isChangingIP: false,
};

export const reducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case types.FETCH_USAGE_CREDIT_HISTORY_PENDING: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case types.GENERATE_API_CREDENTIALS_PENDING: {
      return {
        ...state,
        isGeneratingApiCredentials: true,
      };
    }
    case types.CHANGE_IP_ADDRESS_PENDING: {
      return {
        ...state,
        isChangingIP: true,
      };
    }
    case types.FETCH_API_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        isGeneratingApiCredentials: false,
        isChangingIP: false,
        clientCredentials: data,
      };
    }
    case types.FETCH_USAGE_CREDIT_HISTORY_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        creditHistory: data.data,
        hasMoreCreditHistory: data.has_more,
        totalCreditHistory: data.total,
        totalCreditDebited: data.debited_credits,
        totalCreditCredited: data.credited_credits,
      };
    }
    case types.FETCH_USAGE_CREDIT_HISTORY_MORE: {
      return {
        ...state,
        isFetching: false,
        creditHistory: state.creditHistory.concat(data.data),
        hasMoreCreditHistory: data.has_more,
        totalCreditHistory: data.total,
        totalCreditDebited: data.debited_credits,
        totalCreditCredited: data.credited_credits,
      };
    }
    case types.FETCH_USAGE_CREDIT_HISTORY_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case types.FETCH_ACCOUNT_HISTORY_PENDING: {
      return {
        ...state,
        isAccoutFetching: true,
      };
    }
    case types.FETCH_ACCOUNT_HISTORY_SUCCESS: {
      return {
        ...state,
        isAccoutFetching: false,
        accountHistory: data.data,
        hasMoreAccountHistory: data.has_more,
        totalAccountHistory: data.total,
      };
    }
    case types.FETCH_ACCOUNT_HISTORY_MORE: {
      return {
        ...state,
        isAccoutFetching: false,
        accountHistory: state.accountHistory.concat(data.data),
        hasMoreAccountHistory: data.has_more,
        totalAccountHistory: data.total,
      };
    }
    case types.FETCH_ACCOUNT_HISTORY_FAILURE: {
      return {
        ...state,
        isAccoutFetching: false,
      };
    }
    case types.FETCH_ACTIVITY_LOG_INIT: {
      return {
        ...state,
        isFetchingLog: true,
      };
    }
    case types.FETCH_ACTIVITY_LOG_SUCCESS: {
      return {
        ...state,
        activityLog: data,
        isFetchingLog: false,
      };
    }

    default: {
      return state;
    }
  }
};
