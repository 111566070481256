import { useEffect, useRef } from "react";
import { ToastContainer } from "react-toastify";
import { Route, Routes, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import Emitter from "../constants/Emitter";
import ScrollToTop from "react-scroll-to-top";
import Swal from "sweetalert2";

// Common components
import BrandHeader from "@components/Header/Brand";
import Header from "@components/Header/Landing";
import InternalServerScreen from "@components/InternalServer";
import BrandRoutes from "./Brand";
import Popup from "@components/Popup";

// Components not used in app
import BrandPlanPricingScreen from "@pages/brands/BrandPlanPricingScreen";
import GmailVerifyTokenScreen from "@pages/brands/GmailVerifyTokenScreen";
import BrandSettingWebhookScreen from "../unusedcomponents/BrandSettingWebhook";
import EamilVerificationScreen from "@pages/brands/EamilVerificationScreen";
import ShopifyVerifyTokenScreen from "../unusedcomponents/ShopifyVerifyToken";
import BrandInfluencerDeepScanScreen from "@pages/brands/BrandInfluencerDeepScanScreen";
import AuthShopifyCallbackScreen from "@pages/brands/AuthShopifyCallbackScreen";
import AnalyticsVerifyTokenScreen from "@pages/brands/AnalyticsVerifyTokenScreen";
import PricingLandingScreen from "@pages/landings/PricingLandingScreen";
import ContactScreen from "@pages/landings/ContactScreen";
import InfluencerFullProfileModal from "@components/BrandInfluencerDiscover/Profile/InfluencerFullProfileModal";

const path = window.location.pathname;
const setToken = path.split("/");
const setInvite = path.split("/");
const setPlan = path.split("/");
const verifyToken = path.split("/");

if (path === "/appsumo/registration") {
  localStorage.removeItem("access-token");
  localStorage.removeItem("isLogin");
  localStorage.removeItem("role");
}

const AuthChecker = () => {
  if (path === "/brand/login") {
    if (localStorage.getItem("isLogin")) {
      window.location.href = "/dashboard";
    }
  }
  if (path === "/brand/register") {
    if (localStorage.getItem("isLogin")) {
      window.location.href = "/dashboard";
    }
  }
};

const UrlChecker = () => {
  if (path === "/login") {
    window.location.href = "/brand/login";
  }
  if (path === "/") {
    if (localStorage.role === "brand") {
    }
  }
};

const showHeaderOrFooter = () => {
  return (
    path === "/" ||
    path === "/brand" ||
    path === "/about-us" ||
    path === "/discovery" ||
    path === "/analyzer" ||
    path === "/influencer-marketing" ||
    path === "/discover-recipes" ||
    path === "/affiliate-program" ||
    path === "/managed-services" ||
    path === "/ugc" ||
    path === "/plant-tree" ||
    path === "/monitor-campaign-tools" ||
    path === "/social-monitoring-tools" ||
    path === "/insta-stories-monitoring" ||
    path === "/ecom-video-ads" ||
    path === "/sponsored-posts" ||
    path === "/pricing" ||
    path === "/plan/pricing" ||
    path === "/promotionalpricing" ||
    path === "/brand/login" ||
    path === "/brand/register" ||
    path === "/brand/join/" + verifyToken[3] ||
    path === "/appsumo/registration" ||
    path === "/brand/get-started-free" ||
    path === "/brand/forgot-password" ||
    path === "/account-created" ||
    path === "/terms-of-service" ||
    path === "/privacy-policy" ||
    path === "/contact" ||
    path === "/email/verify/" + verifyToken[3] ||
    path === "/gmail/verify" ||
    path === "/password/reset/" + setToken[3] ||
    path === "/brand/register/" + setPlan[3] ||
    path ===
      "/register/" + setInvite[2] + "/" + setInvite[3] + "/" + setInvite[4] ||
    path === "/500" ||
    path === "/403" ||
    path === "/404"
  );
};

const dynamicClassForHeader = () => {
  return path === "/" ||
    path === "/brand" ||
    path === "/about-us" ||
    path === "/discovery" ||
    path === "/analyzer" ||
    path === "/influencer-marketing" ||
    path === "/discover-recipes" ||
    path === "/affiliate-program" ||
    path === "/managed-services" ||
    path === "/ugc" ||
    path === "/plant-tree" ||
    path === "/social-monitoring-tools" ||
    path === "/monitor-campaign-tools" ||
    path === "/youtube-niche" ||
    path === "/youtube-location" ||
    path === "/tiktok-niche" ||
    path === "/tiktok-location" ||
    path === "/emv-calculator" ||
    path === "/tiktok-engagement-calculator" ||
    path === "/tiktok-email-finder" ||
    path === "/youtube-email-finder" ||
    path === "/insta-stories-monitoring" ||
    path === "/ecom-video-ads" ||
    path === "/sponsored-posts" ||
    path === "/pricing" ||
    path === "/plan/pricing" ||
    path === "/promotionalpricing" ||
    path === "/brand/login" ||
    path === "/account-created" ||
    path === "/brand/register" ||
    path === "/appsumo/registration" ||
    path === "/brand/get-started-free" ||
    path === "/brand/forgot-password" ||
    path === "/terms-of-service" ||
    path === "/privacy-policy" ||
    path === "/contact" ||
    path === "/email/verify/" + verifyToken[3] ||
    path === "/gmail/verify" ||
    path === "/password/reset/" + setToken[3] ||
    path === "/brand/register/" + setPlan[3] ||
    path ===
      "/register/" + setInvite[2] + "/" + setInvite[3] + "/" + setInvite[4] ||
    path === "/500" ||
    path === "/403" ||
    path === "/404"
    ? "w-full relative bg-white overflow-x-hidden grow"
    : "w-full relative bg-[#f4f4f5] overflow-x-hidden grow";
};

const CustomRoutes = () => {
  const popupRef = useRef(null);
  const drawerRef = useRef(null);
  const fullProfileRef = useRef(null);

  useEffect(() => {
    ReactGA.initialize("G-7R5KBTDFKR");
    ReactGA.send("pageview");
    Emitter.on("PERMISSION_POPUP", firePermissionPopup);
    Emitter.on("MESSAGE_POPUP", messagePopup);
    Emitter.on("INFLUENCER_POPUP", influencerPopup);
    Emitter.on("FULL_PROFILE_POPUP", fullProfilePopup);
    return () => Emitter.off("PERMISSION_POPUP");
  }, []);

  const firePermissionPopup = () => {
    Swal.fire({
      title: "Permission denied",
      text: "Don’t have permission to do that action, contact your account admin",
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "",
      confirmButtonColor: "#7c3292",
      cancelButtonColor: "#f4f4f5",
      customClass: {
        actions: "flex-row-reverse",
        closeButton: "hover:text-[#7c3292]",
        confirmButton: "hover:!shadow-none focus:!shadow-none min-w-[100px]",
        cancelButton:
          "hover:!shadow-none focus:!shadow-none min-w-[100px] !text-[#202020]",
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(() => {});
  };

  const messagePopup = (data) => {
    popupRef?.current?.open(data);
  };

  const influencerPopup = (data) => {
    drawerRef?.current?.open(data);
  };

  const fullProfilePopup = (data) => {
    fullProfileRef?.current?.open(data);
  };

  const navigate = useNavigate();

  return (
    <>
      <ToastContainer />
      {AuthChecker()}
      {UrlChecker()}
      {showHeaderOrFooter() ? (
        <Header />
      ) : localStorage.getItem("role") === "brand" ? (
        <BrandHeader navigate={navigate} />
      ) : (
        <Header />
      )}
      <div className={dynamicClassForHeader()}>
        <BrandRoutes />

        <Routes>
          <Route
            exact
            path="/auth/shopify/callback"
            element={<AuthShopifyCallbackScreen />}
          />
          <Route
            exact
            path="/brand/setting-brand-webhook"
            element={<BrandSettingWebhookScreen />}
          />
          <Route
            exact
            path="/plan/pricing"
            element={<BrandPlanPricingScreen />}
          />
          <Route
            exact
            path="/email/verify/:verifyToken"
            element={<EamilVerificationScreen />}
          />
          <Route
            path="/oauth/gmail/callback"
            element={<GmailVerifyTokenScreen />}
          />
          <Route
            path="/oauth/analytics/callback"
            element={<AnalyticsVerifyTokenScreen />}
          />
          <Route
            exact
            path="/shopify/verify"
            element={<ShopifyVerifyTokenScreen />}
          />
          <Route
            exact
            path="/brand/influencer/:platform/:id"
            element={<BrandInfluencerDeepScanScreen />}
          />
          <Route exact path="/pricing" element={<PricingLandingScreen />} />
          <Route exact path="/contact" element={<ContactScreen />} />
          <Route path="/500" element={<InternalServerScreen />} />
        </Routes>
      </div>
      <ScrollToTop className="flex z-[99] items-center justify-center" smooth />
      <Popup ref={popupRef} onClose={() => {}} />
      <InfluencerFullProfileModal ref={fullProfileRef} onClose={() => {}} />
    </>
  );
};

export default CustomRoutes;
