import React from "react";
import { useSelector } from "react-redux";

const Step5 = () => {
  const form = useSelector((state) => state.BrandReports.creationForm);

  return (
    <div className="space-y-7 font-medium">
      <p className="text-gray-600">
        The cost may vary depending upon the number of records. <br />
        <br />
        It takes 1 credit to track 1 influencer
        <br />
        {form.trackingPlatform === "instagram" ? (
          <span>
            Posts/Reels per request (2 credits for both reels and posts)
          </span>
        ) : (
          <span>Videos per request (1 credit for videos)</span>
        )}
        {form.trackingPlatform !== "tiktok" && (
          <>
            <br />
            <span>Stories per request (1 credit for stories)</span>
          </>
        )}
      </p>
      <p className="text-[12px]">
        (number of tracked influencers x days) <br /> If no end date selected,
        it will select 30 days default
      </p>
    </div>
  );
};

export default Step5;
